import React, { useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from "../../logo.svg";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { CurrentReceiptContext } from "../../App";
import axios from "axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
const theme = createTheme();

const Redeem = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentReceipt } = useContext(CurrentReceiptContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const [credit, setCredit] = useState(0);
  async function redeem() {
    if (loading) return;

    setLoading(true);
    try {
      const data = {
        store: currentReceipt.Store.trim(),
        tot_amount: currentReceipt.Total.trim(),
        transaction_id: currentReceipt["Trace Number:"].trim(),
        image_id: currentReceipt.receipt_id,
        location: currentReceipt.shop_info,
        item_list: currentReceipt.item_list,
        retailer_name: location.state.retailer_name,
        email,
      };
      const { data: result } = await axios.post("/receipt/redeem", data);

      if (result.error) {
        setError(result.error);
      } else {
        navigate("/success-page", {
          state: result,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(
    function () {
      if (currentReceipt) {
        let retailer = currentReceipt.retailer;

        let total = parseFloat(currentReceipt["Total"].trim());
        let tot = 0;
        for (const item of location?.state?.item_list || []) {
          tot += parseFloat(item.price.trim());
        }
        total = tot;

        let percent = (total * (retailer.product_discount / 100)).toFixed(2);
        let credit =
          retailer.discount_type === "dollars"
            ? currentReceipt.item_list.length
            : percent;
        setCredit(credit);
      }
    },
    [currentReceipt],
  );
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        className="py-6 text-center flex"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          className="text-black mb-4"
          style={{ marginBottom: 20, fontWeight: 600 }}
        >
          <img src={logo} alt="" width={150} />
        </Typography>
        <Typography
          variant="h4"
          component="div"
          className="text-gray-400 mb-3"
          style={{ marginBottom: 10 }}
        >
          <ReceiptIcon style={{ fontSize: 45 }} />
        </Typography>
        <Typography
          variant="body1"
          component="div"
          style={{
            marginBottom: 20,
          }}
        >
          You'll get ${credit} credit towards future purchases at ORLYBeauty.com
        </Typography>
        <TextField
          id="email"
          label="Your Email Address"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 20 }}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <Button
          variant="contained"
          fullWidth
          style={{
            background: "rgb(232, 156, 174)",
            height: 60,
            opacity: loading ? 0.7 : 1,
          }}
          onClick={redeem}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: "white",
              }}
            ></CircularProgress>
          ) : (
            "Redeem"
          )}
        </Button>

        <Dialog open={error !== null} onClose={() => setError(null)}>
          <DialogTitle color={"red"}>Error</DialogTitle>
          <DialogContent>
            <DialogContentText>{error}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setError(null)} color="error">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default Redeem;
