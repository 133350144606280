// NotFound.js
import React from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <Typography variant="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="textSecondary" paragraph>
        Oops! The page you are looking isn't found
      </Typography>
      <Button component={Link} to="/" variant="outlined" color="primary">
        Go Home
      </Button>
    </div>
  );
};

export default NotFound;
