import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import style from "./snapper.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
// Outside of React component
import { Document, Page, pdfjs } from "react-pdf";
import heic2any from "heic2any";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const CameraComponent = () => {
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [fileType, setFileType] = useState("image");
  const [loading, setLoading] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);
  const [cameraAccessDenied, setCameraAccessDenied] = useState(false);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [cameraFacing, setCameraFacing] = useState("environment");

  const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  };

  const convertHEICtoJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function () {
        const arrayBuffer = reader.result;
        heic2any({
          blob: new Blob([arrayBuffer], { type: "application/octet-stream" }),
          toType: "image/jpeg", // converting to JPEG

          quality: 0.5,
        })
          .then((conversionResult) => {
            resolve(conversionResult);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      const type = file.type.split("/")[1] === "pdf" ? "pdf" : "image";

      const otherFileType =
        file.name.split(".")[file.name.split(".").length - 1];
      console.log("file type = ", file, otherFileType);
      if (type === "pdf") {
        const blobUrl = URL.createObjectURL(file);
        setImage(blobUrl);
        setFileType(type);
      } else if (file.type === "image/heic" || otherFileType === "heic") {
        // Convert HEIC to a compatible format like JPEG or PNG
        // Implement the logic to convert HEIC to JPEG or PNG
        convertHEICtoJPEG(file).then((convertedFile) => {
          setFileBlob(convertedFile);
          reader.onload = () => {
            if (reader.readyState === 2) {
              setImage(reader.result);
              setFileType("image");
            }
          };
          reader.readAsDataURL(convertedFile);
        });
      } else {
        reader.onload = () => {
          if (reader.readyState === 2) {
            setImage(reader.result);

            console.log("else image = ", otherFileType);
            setFileType(otherFileType);
          }
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImage(null);
    }
  };

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const result = await navigator.permissions.query({ name: "camera" });
        setCameraAccessDenied(result.state == "denied");

        // Listen for changes in permission status
        result.onchange = () => {
          setCameraAccessDenied(result.state == "denied");
        };
      } catch (error) {
        console.error("Error checking camera permission:", error);
        setCameraAccessDenied(false); // Set to denied on error
      }
    };

    checkCameraPermission();
  }, []);

  const handleImageDelete = () => {
    setImage(null);
    getUserMedia();
  };

  const handleClickPlaceholder = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = async () => {
    setLoading(true);
    const formData = new FormData();

    if (fileType === "pdf") {
      formData.append("image", fileInputRef.current.files[0]);
    } else if (fileType === "image") {
      formData.append("image", fileBlob, "upload.jpeg");
    } else {
      formData.append("image", fileInputRef.current.files[0]);
    }

    axios
      .post("/receipt/upload", formData)
      .then(function (resp) {
        console.log(resp);

        setLoading(false);
        navigate("/result", {
          state: resp.data,
        });
      })
      .catch(function (err) {
        console.log(err);
        setLoading(false);
        setError("No Products were found");
      });
  };

  const getUserMedia = async () => {
    try {
      setLoading(true);
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log(devices);
      const hasCamera = devices.some((device) => device.kind === "videoinput");

      if (!hasCamera) {
        setCameraAccessDenied(true);
        return;
      }

      // First, get a stream with default constraints to access the camera
      const defaultStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: cameraFacing },
      });

      // Get the video track from the stream
      const videoTrack = defaultStream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();

      // Set max resolution based on the camera capabilities
      const maxWidth = capabilities.width ? capabilities.width.max : 1280; // Default if not available
      const maxHeight = capabilities.height ? capabilities.height.max : 720; // Default if not available
      const streamWidth = 1280;
      const streamHeight = maxHeight > 720 ? 720 : maxHeight;

      // Stop the default stream
      defaultStream.getTracks().forEach((track) => track.stop());

      // Request a new stream with the maximum available resolution
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: cameraFacing,
          // width: {ideal: streamWidth},
          height: { min: streamHeight },
        },
      });

      setStream(newStream); // Assuming setStream is defined elsewhere
      if (videoRef.current) {
        videoRef.current.srcObject = newStream; // Set the new stream to the video element
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserMedia();

    return () => {
      // Stop all tracks when the component unmounts
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [cameraFacing]);

  const takePicture = async () => {
    setLoading(true); // Start loading

    try {
      // Get dimensions of the container
      const cc = document.getElementById("cam-container");
      const ccRate = cc.offsetWidth / cc.offsetHeight;

      const canvasHeight = videoRef.current.videoHeight; // Full height
      const canvasWidth = canvasHeight * ccRate; // Calculate width

      // Create a canvas to capture the image
      const canvas = document.createElement("canvas");
      canvas.width = canvasWidth; // Set calculated width
      canvas.height = canvasHeight; // Set calculated height

      const ctx = canvas.getContext("2d");

      // Draw the video frame on the canvas, centering the image
      ctx.drawImage(
        videoRef.current,
        (videoRef.current.videoWidth - canvasWidth) / 2,
        0,
        canvasWidth,
        canvasHeight,
        0, // Destination X
        0, // Destination Y
        canvasWidth, // Destination Width
        canvasHeight, // Destination Height
      );

      const dataURI = canvas.toDataURL("image/png");

      // Convert dataURI to Blob
      const byteString = atob(dataURI.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: "image/png" });

      // Set the blob and image state
      setFileBlob(blob);
      setImage(dataURI);
      setFileType("image");
    } catch (error) {
      console.error("Error capturing image:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const changeCamera = () => {
    setCameraFacing((prev) => (prev === "user" ? "environment" : "user"));
  };

  return (
    <>
      <div
        className={style.container}
        id="cam-container"
        style={{ opacity: loading ? 0.7 : 1 }}
      >
        <div className={`flex justify-center bg-gray-200`}>
          {image ? (
            fileType == "image" || fileType !== "pdf" ? (
              <img src={image} alt="Uploaded" />
            ) : (
              <div id="ResumeContainer">
                <Document
                  file={image}
                  className={"PDFDocument"}
                  options={options}
                >
                  <Page
                    className={"PDFPage PDFPageOne"}
                    pageNumber={1}
                    scale={1}
                  />
                </Document>
              </div>
            )
          ) : (
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className={style.video}
            />
          )}
          <input
            type="file"
            accept="image/heic,image/*,application/pdf"
            onChange={handleImageUpload}
            ref={fileInputRef}
            style={{ display: "none" }}
            disabled={loading}
          />
        </div>
        <div className={style.btns_section}>
          <IconButton
            sx={{ mx: 1 }}
            style={{
              width: 70,
              height: 70,
              border:
                loading || (cameraAccessDenied && !image)
                  ? "2pt solid #9ca3af"
                  : "2pt solid #e89cae",
              color:
                loading || (cameraAccessDenied && !image)
                  ? "#9ca3af"
                  : "rgb(232, 156, 174)",
            }}
            onClick={image ? handleImageDelete : takePicture}
            disabled={loading || (cameraAccessDenied && !image)}
          >
            {!loading ? (
              image ? (
                <CloseIcon style={{ fontSize: 30 }} />
              ) : cameraAccessDenied ? (
                <NoPhotographyIcon style={{ fontSize: 30 }} />
              ) : (
                <CameraAltIcon style={{ fontSize: 30 }} />
              )
            ) : (
              <CircularProgress
                style={{
                  color: loading ? "#9ca3af" : "rgb(232, 156, 174)",
                }}
              ></CircularProgress>
            )}
          </IconButton>
          <IconButton
            sx={{ mx: 1 }}
            style={{
              width: 70,
              height: 70,
              border: loading ? "2pt solid #9ca3af" : "2pt solid #e89cae",
              color: loading ? "#9ca3af" : "rgb(232, 156, 174)",
            }}
            onClick={image ? uploadImage : handleClickPlaceholder}
            disabled={loading}
          >
            {!loading ? (
              image ? (
                <ArrowRightIcon style={{ fontSize: 50 }} />
              ) : (
                <PermMediaIcon style={{ fontSize: 30 }} />
              )
            ) : (
              <CircularProgress
                style={{
                  color: loading ? "#9ca3af" : "rgb(232, 156, 174)",
                }}
              ></CircularProgress>
            )}
          </IconButton>
        </div>
      </div>

      <Dialog open={error !== null} onClose={() => setError(null)}>
        <DialogTitle color={"red"}>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(null)} color="error">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CameraComponent;
