import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
const theme = createTheme();

const SuccessPage = () => {
  const navigate = useNavigate();
  function goBack() {
    navigate("/snapper");
  }
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        className="py-6 text-center flex"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          className="text-black mb-4"
          style={{ marginBottom: 20, fontWeight: 600 }}
        >
          <img src={logo} alt="" width={150} />
        </Typography>
        <Typography
          variant="h4"
          component="div"
          className="text-gray-400 mb-3"
          style={{ marginBottom: 20 }}
        >
          <ReceiptIcon style={{ fontSize: 45 }} />
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="mb-3"
          style={{ marginBottom: 20 }}
        >
          All set! Check your inbox for information or click below to get
          shopping with your credit.
        </Typography>
        <Button
          onClick={function () {
            window.open("https://orlybeauty.com/", "_blank");
          }}
          variant="contained"
          fullWidth
          style={{
            background: "rgb(232, 156, 174)",

            marginBottom: 20,
          }}
        >
          Go Shopping
        </Button>

        <Button variant="outlined" onClick={goBack} fullWidth color="error">
          Go Back
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default SuccessPage;
