import React from "react";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import img from "../../logo.svg";
import { useLocation } from "react-router-dom";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,

    backgroundColor: "#fdf2ee",
  },
}));

const Sidebar = ({ items, makeActive, activePage }) => {
  const classes = useStyles();

  const location = useLocation();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={true}
      classes={{
        paper:
          classes.drawerPaper + " bg-o-pink-200 border-r border-o-pink-300",
      }}
    >
      <img src={img} alt="" className="w-1/2 m-5" />
      <List>
        {items.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={() => makeActive(item)}
            style={{
              background: activePage === item.name ? "#eed3c8" : undefined,
            }}
          >
            {item.icon}
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
