import React, { useContext, useState } from "react";
import { Button, Container, Grid, TextField, Alert } from "@mui/material";
import axios from "axios";
import { AuthContext } from "../../App";
import logo from "../../logo.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { auth, setAuth } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    try {
      const response = await axios.post("/auth/login", {
        email,
        password,
      });
      // Handle the response data here
      setAuth(response.data);
    } catch (error) {
      // Handle errors here
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("An error occurred. Please try again.");
      }
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        background: "rgb(255 252 251)",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
        }}
        className="sm:justify-center"
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <img src={logo} alt="" width={150} />
        </div>
        <p
          style={{ textAlign: "center", marginBottom: "40px" }}
          className="text-gray-400"
        >
          Get online ORLY Store Credit with proof of purchase in stores.
        </p>
        {error && (
          <Alert
            severity="error"
            style={{
              width: "100%",

              maxWidth: "524px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {error}
          </Alert>
        )}
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                width: "100%",
                marginTop: "20px",
                background: "rgb(232, 156, 174)",

                padding: "20px 0",
              }}
              color="primary"
            >
              Login
            </Button>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
