import React, { useContext, useEffect, useState } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "../login/Login";
import { AuthContext } from "../../App";

import Sidebar from "../../components/sidebar/SideBar";
import ReceiptIcon from "@mui/icons-material/Receipt";
import UserIcon from "@mui/icons-material/AccountCircle";
import Receipts from "./receipts/Receipts";
import StoreIcon from "@mui/icons-material/Store";
import Retailers from "./retailers/Retailers";

// Inside your component

export function MainPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [activePage, setActivePage] = useState("receipts");
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  function makeActive(page) {
    setActivePage(page.name);
  }
  const sidebarItems = [
    {
      text: "Receipts",
      name: "receipts",
      icon: <ReceiptIcon style={{ fontSize: 25, marginRight: 10 }} />,
      component: <Receipts />,
    },

    {
      text: "Retailers",

      name: "retailers",
      icon: <StoreIcon style={{ fontSize: 25, marginRight: 10 }} />,

      component: <Retailers></Retailers>,
    },
    {
      text: "Admins",

      name: "admins",
      icon: <UserIcon style={{ fontSize: 25, marginRight: 10 }} />,
    },
    //
  ];

  function viewPage() {
    for (const page of sidebarItems) {
      if (page.name === activePage) {
        return page.component;
      }
    }
  }
  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex" }}>
      <Sidebar
        items={sidebarItems}
        open={sidebarOpen}
        onClose={handleSidebarToggle}
        activePage={activePage}
        makeActive={makeActive}
      />

      <div className="flex-1 py-12">{viewPage()}</div>
    </div>
  );
}
export default function Dashboard() {
  const { auth, setAuth } = useContext(AuthContext);

  useEffect(function () {
    document.title = "ORLY - Admin Page";
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        Component={() => (auth ? <MainPage /> : <Login />)}
      ></Route>
    </Routes>
  );
}
