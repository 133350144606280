import React, { useState, useEffect, useContext } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  ThemeProvider,
  Typography,
  createTheme,
  CardActionArea,
  Skeleton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CurrentReceiptContext } from "../../App";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    Height: "100vh",
  },
  content: {
    flexGrow: 1,
  },
  card: {
    display: "flex",
    alignItems: "center",
  },
  media: {
    width: "150px", // Adjust the width of the image
    height: "100%", // Adjust the height of the image
    overflow: "hidden",
    objectFit: "cover",
    // marginLeft: 20,
  },
};

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #eeeeee",
        },
      },
    },
  },
});

export function Product({ product }) {
  const product_id = product.item_code.trim();

  const [infos, setInfos] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { currentReceipt, setCurrentReceipt } = useContext(
    CurrentReceiptContext,
  );
  async function fetchProduct() {
    let resp = await axios.post(`/receipt/product/info`, {
      retailer_sku: product_id,
      retailer_name: infos?.shop_name || "ULTA",
    });

    console.log(resp.data);

    if (resp.data.data.search.edges.length)
      return resp.data.data.search.edges[0]?.node;

    return null;
  }
  useEffect(function () {
    console.log("current ", location.state);
    setLoading(true);
    fetchProduct()
      .then(function (result) {
        console.log(result);
        if (result) {
          setInfos({
            title: result.title,
            id: result.id,
            image: result.images.nodes[0].url,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, []);

  if (!infos) {
    return null;
  }
  return (
    <Card style={styles.card}>
      {!loading ? (
        <CardActionArea
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <CardMedia
            style={styles.media}
            component="img"
            image={
              infos?.image ||
              "https://www.byrdie.com/thmb/AHGrUhlX_oSNXEdxQpYP14LUjwE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/We-Tried-the-Most-Popular-Ulta-Brand-Beauty-Products-7348bb9c40564b37bd7be9912f50f7cb.png"
            }
            alt="Placeholder"
          />
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="div">
          Card Title
        </Typography> */}
            <Typography
              variant="body1"
              color="text.secondary"
              className="text-left"
            >
              {infos?.title || product.item}
            </Typography>

            <Typography
              variant="body1"
              color="text.secondary"
              className="text-left"
              style={{ opacity: 0.7 }}
            >
              $ {product.item_price}
            </Typography>
          </CardContent>
        </CardActionArea>
      ) : (
        <Skeleton variant="rectangular" width={400} height={200} />
      )}
    </Card>
  );
}

export function NewProduct({ product }) {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  return (
    <Card style={styles.card}>
      {!loading ? (
        <CardActionArea
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <CardMedia
            style={styles.media}
            component="img"
            image={
              product?.image ||
              "https://www.byrdie.com/thmb/AHGrUhlX_oSNXEdxQpYP14LUjwE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/We-Tried-the-Most-Popular-Ulta-Brand-Beauty-Products-7348bb9c40564b37bd7be9912f50f7cb.png"
            }
            alt="Placeholder"
          />
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="div">
          Card Title
        </Typography> */}
            <Typography
              variant="body1"
              color="text.secondary"
              className="text-left"
            >
              {product?.title}
            </Typography>

            <Typography
              variant="body1"
              color="text.secondary"
              className="text-left"
              style={{ opacity: 0.7 }}
            >
              $ {product.price}
            </Typography>
          </CardContent>
        </CardActionArea>
      ) : (
        <Skeleton variant="rectangular" width={400} height={200} />
      )}
    </Card>
  );
}
const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = useState(location?.state?.item_list || []);

  const [listProd, setListProd] = useState([]);
  const [result, setResult] = useState(location.state);
  const { setCurrentReceipt, currentReceipt } = useContext(
    CurrentReceiptContext,
  );

  const [loading, setLoading] = useState(false);
  function confirm() {
    navigate("/redeem", {
      state: {
        receipt_id: result.receipt_id,
        receipt: currentReceipt,
        item_list: listProd,
      },
    });
  }

  useEffect(function () {
    setCurrentReceipt(location.state);
  }, []);

  async function fetchProduct(product_id) {
    let resp = await axios.post(`/receipt/product/info`, {
      retailer_sku: product_id,
      retailer_name: "ULTA",
    });

    console.log("test ulta = ", resp.data);

    if (resp.data.data.search.edges.length)
      return resp.data.data.search.edges[0]?.node;

    return null;
  }
  useEffect(function () {
    (async function () {
      try {
        let list = [];

        for (const item of location.state.item_list) {
          let product_id = item.item_code.trim();

          console.log("product id = ", product_id);
          const prod = await fetchProduct(product_id);

          if (prod) {
            list.push({
              ...prod,
              price: item.item_price,
              image: prod.images.nodes[0].url,
            });
          }
        }

        setListProd(list);

        // setProducts(list);
      } catch (error) {}
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        className="py-6 text-center flex mx-0"
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
        theme={theme}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              className="text-gray-400 mb-3"
              style={{ paddingBottom: 10 }}
            >
              <ReceiptIcon style={{ fontSize: 45 }} />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              className="text-gray-300 pb-5"
              fontWeight={"600"}
            >
              We found {listProd.length} ORLY products.
            </Typography>

            <Typography variant="p" component="div" className="text-gray-300">
              <span className="text-gray-400">Total:</span> {result.Total}${" "}
              <span className="text-gray-400">Transaction ID:</span>{" "}
              {result["Trace Number:"]}
            </Typography>
            <Typography variant="p" component="div" className="text-gray-300">
              <span className="text-gray-400">Location:</span>{" "}
              {result.shop_info}
            </Typography>
          </Grid>
          <div
            style={{
              maxHeight: "calc(100vh - 200px)", // Adjust the value based on the header and footer height
              overflow: "auto",
            }}
          >
            <Grid container spacing={3} className="py-10 px-5">
              {listProd?.map(function (product, index) {
                return (
                  <Grid item xs={12} sm={4} flex="1 1 auto" key={index}>
                    <NewProduct product={product} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          item
          xs={12}
          style={{ marginTop: "auto", paddingTop: 15 }}
        >
          <Grid item xs={6} sm={3} sx={{ padding: "0 8px" }}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={function () {
                navigate("/snapper");
              }}
            >
              Go Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={3} sx={{ padding: "0 8px" }}>
            <Button
              variant="contained"
              fullWidth
              onClick={confirm}
              style={{
                background: "rgb(232, 156, 174)",
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Result;
