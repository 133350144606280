import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  IconButton,
  TablePagination,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";

export default function Retailers() {
  const [retailers, setRetailers] = useState([]);
  const [error, setError] = useState(null);
  const [isAddRetailerModalOpen, setAddRetailerModalOpen] = useState(false);
  const [isEditRetailerModalOpen, setEditRetailerModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [newRetailer, setNewRetailer] = useState({
    retailer_name: "",
    retailer_metafield: "",
    retailer_address: "",
    retailer_id: "",
  });

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [editRetailer, setEditRetailer] = useState(null);

  useEffect(() => {
    // Fetch retailers data from the API
    axios
      .post("/api/retailers/list", {
        page,
        perPage: rowsPerPage,
      })
      .then((response) => setRetailers(response.data.retailers))
      .catch((error) => {
        console.error("Error fetching retailers:", error);
        setError("Error fetching retailers. Please try again.");
      });
  }, [page]);

  const handleCloseErrorModal = () => {
    setError(null);
  };

  const handleAddRetailer = () => {
    setAddRetailerModalOpen(true);
  };

  const handleCloseAddRetailerModal = () => {
    setAddRetailerModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRetailer((prevRetailer) => ({ ...prevRetailer, [name]: value }));
  };

  const handleAddRetailerSubmit = () => {
    // Add logic to send a request to add a retailer

    if (isLoading) return;

    setLoading(true);
    axios
      .post("/api/retailers/add", newRetailer)
      .then((response) => {
        // Add the new retailer to the list
        setRetailers([...retailers, response.data.retailer]);
        setAddRetailerModalOpen(false);
      })
      .catch((error) => {
        console.error("Error adding retailer:", error);
        setError("Error adding retailer. Please try again.");
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const handleEditRetailer = (retailer) => {
    setEditRetailer(retailer);
    setEditRetailerModalOpen(true);
  };

  const handleCloseEditRetailerModal = () => {
    setEditRetailer(null);
    setEditRetailerModalOpen(false);
  };

  const handleEditRetailerSubmit = () => {
    setLoading(true);

    // Assuming the retailer ID is available in editRetailer.id
    axios
      .post(`/api/retailers/update/${editRetailer.id}`, editRetailer)
      .then((response) => {
        const updatedRetailers = retailers.map((r) =>
          r.id === editRetailer.id ? response.data.retailer : r,
        );
        setRetailers(updatedRetailers);
        setEditRetailer(null);
        setEditRetailerModalOpen(false);
      })
      .catch((error) => {
        console.error("Error updating retailer:", error);
        setError("Error updating retailer. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteRetailer = (retailerId) => {
    setLoading(true);

    // Assuming the retailer ID is available in the retailerId parameter
    axios
      .post(`/api/retailers/delete/${retailerId}`)
      .then(() => {
        const updatedRetailers = retailers.filter((r) => r.id !== retailerId);
        setRetailers(updatedRetailers);
      })
      .catch((error) => {
        console.error("Error deleting retailer:", error);
        setError("Error deleting retailer. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          className="text-o-pink-300"
          style={{ marginBottom: 15 }}
        >
          Retailers
        </Typography>

        <div>
          <Button
            variant="contained"
            style={{ background: "rgb(238,211,200)" }}
            onClick={handleAddRetailer}
          >
            Add Retailer
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Retailer ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Metafield</TableCell>
              <TableCell>Discount Type</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Actions</TableCell>
              {/* Add more columns as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {retailers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No retailers found.
                </TableCell>
              </TableRow>
            ) : (
              retailers.map((retailer) => (
                <TableRow key={retailer.id}>
                  <TableCell>{retailer.id}</TableCell>
                  <TableCell>{retailer.retailer_id}</TableCell>
                  <TableCell>{retailer.retailer_name}</TableCell>
                  <TableCell>{retailer.retailer_metafield}</TableCell>
                  <TableCell>
                    {retailer.discount_type === "dollars"
                      ? "1$ Per Product"
                      : `${retailer.product_discount}% Of Total`}
                  </TableCell>

                  <TableCell>{retailer.retailer_address}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleEditRetailer(retailer)}
                      aria-label="edit"
                      variant="contained"
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteRetailer(retailer.id)}
                      aria-label="delete"
                      variant="contained"
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 25, 30, 35, 40, 45, 50]}
        component="div"
        count={retailers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Error Modal */}

      <Dialog
        open={isAddRetailerModalOpen}
        onClose={handleCloseAddRetailerModal}
        aria-labelledby="add-retailer-modal-title"
        aria-describedby="add-retailer-modal-description"
      >
        <DialogTitle id="add-retailer-modal-title">Add Retailer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the retailer information below:
          </DialogContentText>

          <TextField
            margin="dense"
            label="Retailer ID"
            name="retailer_id"
            value={newRetailer.retailer_id}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="retailer_name"
            value={newRetailer.retailer_name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Metafield"
            name="retailer_metafield"
            value={newRetailer.retailer_metafield}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address"
            name="retailer_address"
            value={newRetailer.retailer_address}
            onChange={handleInputChange}
            fullWidth
          />

          {/* Discount Type Dropdown */}
          <FormControl fullWidth>
            <InputLabel id="discount-type-label">Discount Type</InputLabel>
            <Select
              labelId="discount-type-label"
              id="discount-type"
              name="discount_type"
              value={newRetailer.discount_type}
              onChange={handleInputChange}
            >
              <MenuItem value="dollars">$1 per product</MenuItem>
              <MenuItem value="percent">Percent of Total</MenuItem>
            </Select>
          </FormControl>

          {/* Coupon Percent Input (visible only when percent is selected) */}
          {newRetailer.discount_type === "percent" && (
            <TextField
              margin="dense"
              label="Coupon Percent"
              name="product_discount"
              value={newRetailer.coupon_percent}
              onChange={handleInputChange}
              fullWidth
              type="number"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddRetailerModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddRetailerSubmit}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Add Retailer"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={error !== null} onClose={() => setError(null)}>
        <DialogTitle color={"red"}>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(null)} color="error">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Retailer Modal */}
      <Dialog
        open={isEditRetailerModalOpen}
        onClose={handleCloseEditRetailerModal}
        aria-labelledby="edit-retailer-modal-title"
        aria-describedby="edit-retailer-modal-description"
      >
        <DialogTitle id="edit-retailer-modal-title">Edit Retailer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please update the retailer information below
          </DialogContentText>

          <TextField
            margin="dense"
            label="Retailer ID"
            name="retailer_id"
            value={editRetailer?.retailer_id || ""}
            onChange={(e) =>
              setEditRetailer((prevRetailer) => ({
                ...prevRetailer,
                retailer_id: e.target.value,
              }))
            }
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="retailer_name"
            value={editRetailer?.retailer_name || ""}
            onChange={(e) =>
              setEditRetailer((prevRetailer) => ({
                ...prevRetailer,
                retailer_name: e.target.value,
              }))
            }
            fullWidth
          />
          <TextField
            margin="dense"
            label="Metafield"
            name="retailer_metafield"
            value={editRetailer?.retailer_metafield || ""}
            onChange={(e) =>
              setEditRetailer((prevRetailer) => ({
                ...prevRetailer,
                retailer_metafield: e.target.value,
              }))
            }
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address"
            name="retailer_address"
            value={editRetailer?.retailer_address || ""}
            onChange={(e) =>
              setEditRetailer((prevRetailer) => ({
                ...prevRetailer,
                retailer_address: e.target.value,
              }))
            }
            fullWidth
          />

          {/* Discount Type Dropdown */}
          <FormControl fullWidth>
            <InputLabel id="discount-type-label">Discount Type</InputLabel>
            <Select
              labelId="discount-type-label"
              id="discount-type"
              name="discount_type"
              value={editRetailer?.discount_type || ""}
              onChange={function (e) {
                setEditRetailer((prevRetailer) => ({
                  ...prevRetailer,
                  discount_type: e.target.value,
                }));
              }}
            >
              <MenuItem value="dollars">$1 per product</MenuItem>
              <MenuItem value="percent">Percent of Total</MenuItem>
            </Select>
          </FormControl>

          {/* Coupon Percent Input (visible only when percent is selected) */}
          {editRetailer?.discount_type === "percent" && (
            <TextField
              margin="dense"
              label="Coupon Percent"
              name="product_discount"
              value={editRetailer?.product_discount || ""}
              onChange={function (e) {
                setEditRetailer((prevRetailer) => ({
                  ...prevRetailer,
                  product_discount: e.target.value,
                }));
              }}
              fullWidth
              type="number"
            />
          )}

          {/* Add more input fields for other retailer properties */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditRetailerModal}
            color="primary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditRetailerSubmit}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Update Retailer"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
