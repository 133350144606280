import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TablePagination,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Download } from "@mui/icons-material";

const FileDownloadButton = ({ fileUrl }) => {
  const [fileName, setFileName] = useState("");

  const downloadFile = async () => {
    try {
      // Create a download link
      const link = document.createElement("a");
      link.href = new URL(fileUrl, axios.defaults.baseURL);

      link.download = "";

      // Append the link to the document and trigger a click to start the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <IconButton
      variant="contained"
      style={{ marginRight: 15 }}
      color="primary"
      onClick={downloadFile}
    >
      <Download />
    </IconButton>
  );
};
export default function Receipts() {
  const [receipts, setReceipts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);

  async function fetchReceipts() {
    if (loading) return;
    const response = await axios.post("/receipt/list", {
      page,
      perPage: rowsPerPage,
    });
    return response.data;
  }

  // Assuming you fetch the receipts data from an API or some source
  useEffect(() => {
    // Fetch receipts data and set it to the state
    // Example: fetchReceipts().then(data => setReceipts(data));

    console.log("page changed");
    setLoading(true);
    fetchReceipts()
      .then(function (data) {
        if (data) {
          for (const receipt of data.receipts) {
            console.log(
              `receipt/download/${receipt.file_path.split("/").pop()}`,
            );
          }
          setReceipts(data.receipts);
        }
      })
      .finally(function () {
        setLoading(false);
      });
  }, [page]);

  // Function to format date as "Added at"
  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return `${date.toLocaleString()}`;
  };

  const handleApprove = async (receiptId) => {
    // Handle approve logic, e.g., send a request to the server
    console.log(`Approve receipt with ID ${receiptId}`);

    const mail = await axios.post("api/receipt/approve", {
      receipt_id: receiptId,
    });

    console.log(mail);

    let new_list = receipts.map(function (data) {
      if (data.id === receiptId) return { ...data, is_approved: true };

      return data;
    });

    console.log(new_list);

    setReceipts(new_list);
  };

  const handleDecline = async (receiptId) => {
    // Handle decline logic, e.g., send a request to the server
    const mail = await axios.post("api/receipt/decline", {
      receipt_id: receiptId,
    });

    console.log(mail);

    let new_list = receipts.map(function (data) {
      if (data.id === receiptId) return { ...data, is_approved: false };

      return data;
    });

    console.log(new_list);

    setReceipts(new_list);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Typography
        variant="h5"
        className="text-o-pink-300 "
        style={{ marginBottom: 15 }}
      >
        Receipts
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Added At</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Retailer</TableCell>
              <TableCell>Store</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Credit</TableCell>
              <TableCell>File</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipts.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Receipts found.
                </TableCell>
              </TableRow>
            ) : (
              receipts.map((receipt) => (
                <TableRow key={receipt.id}>
                  <TableCell>{formatCreatedAt(receipt.created_at)}</TableCell>
                  <TableCell>{receipt.email}</TableCell>

                  <TableCell>{receipt.retailer_name}</TableCell>
                  <TableCell>{receipt.store}</TableCell>
                  <TableCell>{receipt.transaction_id}</TableCell>
                  <TableCell>{receipt.location}</TableCell>
                  <TableCell>
                    $ {parseFloat(receipt.credit).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {/* {receipt.file_path.split("/").pop()} */}
                    <FileDownloadButton
                      fileUrl={`receipt/download/${receipt.file_path
                        .split("/")
                        .pop()}`}
                    />
                  </TableCell>
                  <TableCell style={{ display: "flex", border: "none" }}>
                    {receipt.is_approved === true ? (
                      "Approved"
                    ) : receipt.is_approved === null ? (
                      <>
                        <IconButton
                          variant="contained"
                          style={{ marginRight: 15 }}
                          color="primary"
                          onClick={() => handleApprove(receipt.id)}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          color="error"
                          onClick={() => handleDecline(receipt.id)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </>
                    ) : (
                      "Declined"
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 25, 30, 35, 40, 45, 50]}
        component="div"
        count={receipts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}

{
  /* <Typography>No receipts available.</Typography> */
}
