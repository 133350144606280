import { RouterProvider, createHashRouter } from "react-router-dom";
import Main from "./pages/Main";
import React, { ReactDOM, useState } from "react";
import Snapper from "./pages/snapper/Snapper";
import Result from "./pages/result/Result";
import Redeem from "./pages/redeeem/Redeem";
import SuccessPage from "./pages/success-page/SuccessPage";
import Login from "./pages/login/Login";
import Dashboard from "./pages/admin/Dashboard";
import NotFound from "./pages/error/404";

const ROUTE_URL = "/";

function get_path(path) {
  return ROUTE_URL + path;
}

export const AuthContext = React.createContext({});
export const CurrentReceiptContext = React.createContext({});
const router = createHashRouter([
  {
    path: get_path("/"),
    element: <Main />,
    id: "main",
  },

  {
    path: get_path("/snapper"),
    element: <Snapper />,
    id: "snapper",
  },

  {
    path: get_path("/result"),
    element: <Result />,
    id: "result",
  },

  {
    path: get_path("/redeem"),
    element: <Redeem />,
    id: "redeem",
  },
  {
    path: get_path("/success-page"),
    element: <SuccessPage />,
    id: "success-page",
  },

  {
    path: get_path("/admin"),
    element: <Dashboard />,
  },
]);
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound></NotFound>;
    }

    return this.props.children;
  }
}
export default function App() {
  const [auth, setAuth] = useState(null);

  const [currentReceipt, setCurrentReceipt] = useState(null);
  return (
    <React.StrictMode>
      <AuthContext.Provider value={{ auth, setAuth }}>
        {/* {auth ? <RouterProvider router={router} /> : <Login />} */}
        <ErrorBoundary>
          <CurrentReceiptContext.Provider
            value={{ currentReceipt, setCurrentReceipt }}
          >
            <RouterProvider router={router} />
          </CurrentReceiptContext.Provider>
        </ErrorBoundary>
      </AuthContext.Provider>
    </React.StrictMode>
  );
}
