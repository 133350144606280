import { Button } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import style from "./main.module.css";
import { Link } from "react-router-dom";
// rgb(232, 156, 174)
import logo from "./ORLY_Logo_4x.png";

export default function Main() {
  return (
    <div className={style.container}>
      <div className={style.report_section}>
        <div>
          <img src={logo} alt="" className="mx-auto mt-10 mb-4 max-w-[185px]" />
          <p className={style.head_description}>
            EARN ORLY PERKS + STORE CREDIT WHEN YOU SCAN PROOF OF PURCHASE IN
            SELECT STORES
          </p>
        </div>
        <div className={style.btns_section}>
          <Link to={"snapper"}>
            <Button
              className={style["btn"]}
              style={{
                width: "auto",
                height: "50px",
                background: "rgb(255,255,255)",
                color: "rgb(232, 156, 174)",
                fontSize: 18,
                padding: "6px 28px",
              }}
              variant="contained"
            >
              <AddAPhotoIcon style={{ marginRight: 10, marginBottom: 2 }} />
              SCAN NOW
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
